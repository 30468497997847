import React, {useEffect, useState} from "react";
import {List, Datagrid, TextField, FunctionField, Loading} from 'react-admin'
import {Select, MenuItem, Card} from '@material-ui/core'
import {useHistory} from "react-router-dom";
import {proposalServices} from "../../services/proposal";
import { CreatedByField, LastModifiedField, RecordDateStringField, TotalCensusCount } from '../../utils/helpers';
import Filters from "../../utils/Filters";
import { userServices } from '../../services/user';
import {handleExcel} from "../form/utils/employeeHelper";
import { ancillaryPlansToDownloadSeparated } from "../../constants/plans";
import moment from "moment";

const ProposalList = props => {
  const [redirect, setRedirect] = useState();
  const [loading, setLoading] = useState();
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setUsers(await userServices.getAll())
    })()
  }, [])

  const handleUpdate = record => setRedirect(`/proposal/${record.id}`)

  const handleDuplicate = record => {
    const omitFields = ({id, createdAt, updatedAt, user, createdBy, ...rest}) => rest;
    const data = omitFields(record);
    const json = JSON.stringify(data);
    const encodedJson = encodeURIComponent(json);
    const url = `/proposal/create?source=${encodedJson}`;
    setRedirect(url);
  }

  const separatedMedicalAndAncillaryPlans = (record) => {
    const plans = record.plans
    const effectiveYear = moment(record.effectiveDate).year()
    const separatedPlans = ancillaryPlansToDownloadSeparated[effectiveYear]
    const hasAncillaryPlans = plans.some(plan => separatedPlans?.includes(plan.name))
    return hasAncillaryPlans
  }

  const handlePdf = async (record) => {
    setLoading(["Generating PDF", "It might take a while"])

    const planYear = Number(record.effectiveDate.split("-")[0]);

    await proposalServices.downloadPDF(
      record.id, 
      record.company?.name, 
      record.updatedAt,
      separatedMedicalAndAncillaryPlans(record),
      planYear
    )

    setLoading(null)
  }

  const handleAction = (action, record) => {
    const handlers = {
      'update': handleUpdate,
      'duplicate': handleDuplicate,
      'pdf': handlePdf,
      'excel': handleExcel,
    }

    return handlers[action](record)
  }

  return <>
    {redirect && history.push(redirect)}
    {loading ? <Loading loadingPrimary={loading[0]} loadingSecondary={loading[1]}/> :
      <List
        {...props}
        bulkActionButtons={false}
        filters={<Filters users={users} />}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid>
          <TextField source={'company.name'} sortable={false} label="Company"/>
          <TextField source={'company.contactName'} sortable={false} label="Contact Name"/>
          <TextField source={'company.contactEmail'} sortable={false} label="Email"/>
          <FunctionField label={'City - ZIP'} render={record => `${record?.company.city} - ${record?.company.zip}`}/>
          <TextField source={'brokerName'} label="Broker"/>
          <RecordDateStringField field="effectiveDate" source={'effectiveDate'}/>
          <LastModifiedField source="updatedAt" label="Last Modified"/>
          <CreatedByField source="createdAt" label="Created By"/>
          <FunctionField label={'EE Count'} render={record => record.employeeCensus.length} />
          <TotalCensusCount label="Total Census Count"/>
          <FunctionField
            label="Status"
            render={record =>
              <Card style={{textAlign: 'center'}}>
                {record.status}
              </Card>}
          />
          <FunctionField render={record =>
            <Select value={0} onChange={e => handleAction(e.target.value, record)}>
              <MenuItem disabled value={0}>Actions</MenuItem>
              <MenuItem value={'update'}>Update</MenuItem>
              <MenuItem value={'duplicate'}>Duplicate</MenuItem>
              <MenuItem value={'pdf'}>PDF</MenuItem>
              <MenuItem value={'excel'}>Excel</MenuItem>
            </Select>
          }/>
        </Datagrid>
      </List>
    }
  </>
}

export default ProposalList